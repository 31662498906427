
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

// export const firebaseConfig = {
//     apiKey: "AIzaSyDRmOr3ZZMGOTXmwo-94hi3mPCvnrrurc8",
//     authDomain: "my-test-firebase-e0a75.firebaseapp.com",
//     databaseURL: "https://my-test-firebase-e0a75.firebaseio.com",
//     projectId: "my-test-firebase-e0a75",
//     storageBucket: "my-test-firebase-e0a75.appspot.com",
//     messagingSenderId: "5608425362",
//     appId: "1:5608425362:web:e1277ef2eeb8d32a"
// };
// Pong fire


export const firebaseConfig = {
  apiKey: "AIzaSyAmJ_kd7klAiDUFt42OVd15FssG0fUVBuE",
  authDomain: "tutorapp-d74b8.firebaseapp.com",
  databaseURL: "https://tutorapp-d74b8.firebaseio.com",
  projectId: "tutorapp-d74b8",
  storageBucket: "tutorapp-d74b8.appspot.com",
  messagingSenderId: "972130892696",
  appId: "1:972130892696:web:55769931e1608dca95c9ed"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
