import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'show-data',
    pathMatch: 'full'
  },
  // {
  //   path: 'home',
  //   loadChildren: './home/home.module#HomePageModule'
  // },
  // {
  //   path: 'list',
  //   loadChildren: './list/list.module#ListPageModule'
  // },
  { path: 'add-data', loadChildren: './add-data/add-data.module#AddDataPageModule' },
  { path: 'show-data', loadChildren: './show-data/show-data.module#ShowDataPageModule' },
  { path: 'login-auth', loadChildren: './login-auth/login-auth.module#LoginAuthPageModule' },
  { path: 'login-auth/0', loadChildren: './login-auth/login-auth.module#LoginAuthPageModule' },
  { path: 'edit-data/:idItem', loadChildren: './edit-data/edit-data.module#EditDataPageModule' },
  { path: 'add-data2', loadChildren: './add-data2/add-data2.module#AddData2PageModule' },
  { path: 'add-data3', loadChildren: './add-data3/add-data3.module#AddData3PageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules , useHash : true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
