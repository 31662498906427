import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    // {
    //   title: 'Home',
    //   url: '/home',
    //   icon: 'home'
    // },
    // {
    //   title: 'List',
    //   url: '/list',
    //   icon: 'list'
    // },
    {
      title: 'เพิ่มบทเรียนคณิตศาสตร์',
      url: '/add-data',
      icon: 'add-circle'
    }
    ,
    {
      title: 'เพิ่มบทเรียนวิทย์',
      url: '/add-data2',
      icon: 'ios-book'
    },
    {
      title: 'เพิ่มบทเรียนอังกฤษ',
      url: '/add-data3',
      icon: 'ios-book'
    },
    {
      title: 'ดูบทเรียน',
      url: '/show-data',
      icon: 'ios-book'
    }
    ,
    {
      title: 'Login Page',
      url: '/login-auth/0',
      icon: 'ios-log-in'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
